// External librairies
import React, {useEffect, useContext, useState} from "react";
import {useForm, Controller} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {toast} from "react-toastify";

// Query
import {useMutationIssueAdd, useMutationUploadImages, useQueryCategories} from "../../queries";

// Context
import {AuthContext} from "../../contexts";

// Components
import {
    NavigationHeader,
    TextInput,
    TextAreaInput,
    RadioInput,
    DateInput,
    SelectInput,
    ImageInput,
    FormButton,
    SimpleMap,
} from "../../components";

// Confing
import {STATUS, PRIVACY} from "../../config/config";
import {formSchemaIssueAdd} from "../../config/yupconfig";

// Misc
import {Transition} from "../../utils/";
import {useNavigate} from "react-router-dom";

const ReportsPageCreate = () => {
    const {user} = useContext(AuthContext);
    const {mutate: postReport} = useMutationIssueAdd();
    const {mutate: uploadImages} = useMutationUploadImages();
    const {isLoading: isLoadingCategories, data: dataCategories} = useQueryCategories();
    const [images, setImages] = useState([]);
    const navigate = useNavigate();


    const {
        control,
        register,
        formState: {errors, isValid},
        handleSubmit,
        reset,
    } = useForm({
        resolver: yupResolver(formSchemaIssueAdd),
    });

    useEffect(() => {
        let defaultValues = {};
        defaultValues.privacyChoice = "private";
        defaultValues.location = {
            lat: parseFloat(process.env.REACT_APP_CITY_LAT),
            lng: parseFloat(process.env.REACT_APP_CITY_LNG),
        };
        defaultValues.images = [];
        defaultValues.status = 'new';
        if (!isLoadingCategories) {
            defaultValues.category = dataCategories[0].value
        }
        reset({...defaultValues});
    }, [isLoadingCategories]);

    const onSubmit = async (data, e) => {
        e.preventDefault();
        const newIssueData = {
            date: data.dateStart,
            title: data.title,
            description: data.description,
            status: data.status,
            end: data.dateEnd,
            lat: data.location.lat,
            lon: data.location.lng,
            isPublic: data.privacyChoice === "public",
            user: user.id,
            category: data.category,
        };
        if (images.length > 0) {
            await uploadImages(images, {
                onSuccess: (res) => {
                    newIssueData.images = res.data.data
                    postReport(newIssueData, {
                        onSuccess: () => {
                            navigate("/reports");
                            toast.success("L'incident a été créé !")
                        },
                        onError: () => toast.error("Une erreur est survenue"),
                    });
                },
                onError: () => toast.error("Les images n'ont pas été enregistrées."),
            })
        } else {
            postReport(newIssueData, {
                onSuccess: () => {
                    navigate("/reports");
                    toast.success("L'incident a été créé !")
                },
                onError: () => toast.error("Une erreur est survenue"),
            });
        }
    };

    return (
        <Transition>
            <div className="w-full lg:w-[46rem] flex flex-col gap-y-5">
                <NavigationHeader
                    title="Incident"
                    subTitle="Déclarer un incident"
                    returnUrl="/reports"
                />
                <form
                    className="flex flex-col"
                    onSubmit={(e) => {
                        handleSubmit(onSubmit)(e);
                    }}
                >
                    <div className="flex flex-col gap-y-10">
                        <div className="grid grid-cols-1 gap-y-8">
                            <div className="flex flex-col gap-y-5 md:flex-row md:items-center md:gap-x-4 md:gap-y-0">
                                {PRIVACY &&
                                    PRIVACY.map((privacy) => {
                                        return (
                                            <div key={privacy.id}>
                                                <RadioInput
                                                    id={privacy.value}
                                                    label={privacy.label}
                                                    register={{
                                                        ...register(
                                                            "privacyChoice",
                                                            {required: true}
                                                        ),
                                                    }}
                                                    value={privacy.value}
                                                    bg={privacy.bg}
                                                    bgFade={privacy.bgFade}
                                                    border={privacy.border}
                                                    color={privacy.color}
                                                    error={
                                                        errors?.privacyChoice
                                                    }
                                                />
                                            </div>
                                        );
                                    })}
                            </div>
                            <div className="flex flex-col md:flex-row md:justify-between gap-y-8 md:gap-y-0 gap-x-5">
                                <div className="flex flex-col">
                                    <SelectInput
                                        id="status"
                                        label="Status"
                                        items={STATUS}
                                        noValue="Status"
                                        register={{
                                            ...register("status", {
                                                required: true,
                                            }),
                                        }}
                                        error={errors?.status}
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <SelectInput
                                        id="category"
                                        label="Catégorie"
                                        items={dataCategories}
                                        noValue="Catégorie"
                                        register={{
                                            ...register("category", {
                                                required: true,
                                            }),
                                        }}
                                        error={errors?.category}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col md:flex-row md:justify-between gap-y-8 md:gap-y-0 gap-x-5">
                                <div className="flex flex-col">
                                    <DateInput
                                        id="dateStart"
                                        label="Date de début"
                                        register={{
                                            ...register("dateStart", {
                                                required: true,
                                            }),
                                        }}
                                        error={errors?.dateStart}
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <DateInput
                                        id="dateEnd"
                                        label="Date de fin"
                                        register={{
                                            ...register("dateEnd", {
                                                required: true,
                                            }),
                                        }}
                                        error={errors?.dateEnd}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col md:flex-row gap-y-8 md:gap-y-0 gap-x-5">
                                <div className="flex flex-col">
                                    <TextInput
                                        id="title"
                                        label="Titre"
                                        register={{
                                            ...register("title", {
                                                required: true,
                                            }),
                                        }}
                                        error={errors?.title}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col md:flex-row gap-y-8 md:gap-y-0 gap-x-5">
                                <div className="flex flex-col">
                                    <TextAreaInput
                                        id="description"
                                        label="Description"
                                        register={{
                                            ...register("description", {
                                                required: true,
                                            }),
                                        }}
                                        error={errors?.description}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col gap-y-8">
                                <div className="flex flex-col">
                                    <Controller
                                        control={control}
                                        name="location"
                                        render={({
                                                     field: {onChange, value},
                                                 }) =>
                                            value && (
                                                <div
                                                    className="relative rounded-lg mt-10 h-96 pb-5 shadow-custom overflow-hidden">
                                                    <SimpleMap
                                                        location={value}
                                                        onChange={onChange}
                                                        isMarkerFixed={false}
                                                    />
                                                </div>
                                            )
                                        }
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <label>Images:</label>
                                    <ImageInput
                                        images={images}
                                        setImages={setImages}
                                        error={false} // Mettez à true si besoin
                                        errorText="Erreur de chargement d'image"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="w-full flex justify-end">
                            <FormButton
                                caption="Ajouter le signalement"
                                icon="issue"
                                isValid={isValid}
                            />
                        </div>
                    </div>
                </form>
            </div>
        </Transition>
    );
};

export default ReportsPageCreate;
