// External librairies
import React, {Fragment} from "react";

// Icons
import {APIProvider, Map, Marker} from '@vis.gl/react-google-maps';

const SimpleMap = ({location, onChange, isMarkerFixed = true}) => {
    const defaultProps = {
        center: location
            ? location
            : {
                lat: parseFloat(process.env.REACT_APP_CITY_LAT),
                lng: parseFloat(process.env.REACT_APP_CITY_LNG),
            },
        zoom: 11,
    };

    const handleDragEnd = (e) => {
        const newPosition = {
            lat: e.latLng.lat(),
            lng: e.latLng.lng(),
        };
        onChange(newPosition);
    };


    return (
            <APIProvider apiKey={process.env.REACT_APP_GOOGLE_API_KEY}>
                <Map
                    defaultCenter={defaultProps.center}
                    defaultZoom={defaultProps.zoom}
                    gestureHandling={'greedy'}
                    disableDefaultUI={true}
                >
                    <Marker
                        position={defaultProps.center}
                        draggable={!isMarkerFixed}
                        onDragEnd={handleDragEnd}
                    />
                </Map>
            </APIProvider>
    );
};

export default SimpleMap;
